<template>
  <p class="text-gray-500 text-sm italic mb-5">
    <span class="text-red-500">*</span> -
    {{ lang === "mn" ? "Заавал бөглөх" : "Must be filled" }}
  </p>

  <div class="flex max-sm:flex-col gap-5 md:gap-10 w-full">
    <div class="w-full">
      <label for="nameMn"
        >{{ lang === "mn" ? "Нэр /монголоор/:" : "Name by Mongolian:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="nameMn"
        name="nameMn"
        type="text"
        placeholder="Нэр"
        class="w-full p-2 border"
        v-model="form.nameMn"
      />
    </div>

    <div class="w-full">
      <label for="nameEn"
        >{{ lang === "mn" ? "Нэр /англиар/:" : "Name by English:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="nameEn"
        name="nameEn"
        type="text"
        placeholder="Name"
        class="w-full p-2 border"
        v-model="form.nameEn"
      />
    </div>
  </div>

  <div class="flex max-sm:flex-col gap-5 md:gap-10 w-full my-5">
    <div class="w-full">
      <label for="professionMn"
        >{{
          lang === "mn" ? "Мэргэжил /монголоор/:" : "Profession by Mongolian:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="professionMn"
        name="professionMn"
        type="text"
        placeholder="Мэргэжил"
        class="w-full p-2 border"
        v-model="form.professionMn"
      />
    </div>

    <div class="w-full">
      <label for="professionEn"
        >{{ lang === "mn" ? "Мэргэжил /англиар/:" : "Profession by English:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="professionEn"
        name="professionEn"
        type="text"
        placeholder="Profession"
        class="w-full p-2 border"
        v-model="form.professionEn"
      />
    </div>
  </div>

  <div class="flex max-sm:flex-col gap-5 md:gap-10 w-full my-5">
    <div class="flex flex-col w-full">
      <label for="phone"
        >Утасны дугаар:<span class="text-red-500">*</span></label
      >
      <input
        id="phone"
        name="phone"
        type="text"
        placeholder="Утас"
        class="w-full p-2 border"
        v-model="form.phone"
      />
    </div>

    <div class="w-full"></div>
  </div>

  <label for="adviceMn">{{
    lang === "mn"
      ? "Зөвлөгөөний хүрээ /монголоор/:"
      : "Advice Range by Mongolian:"
  }}</label>
  <textarea
    id="adviceMn"
    name="adviceMn"
    type="text"
    rows="4"
    placeholder="Зөвлөгөө"
    class="w-full p-2 border mb-5"
    v-model="form.adviceMn"
  >
  </textarea>

  <label for="adviceEn" class="my-5">{{
    lang === "mn" ? "Зөвлөгөөний хүрээ /англиар/:" : "Advice Range by English:"
  }}</label>
  <textarea
    id="adviceEn"
    name="adviceEn"
    type="text"
    rows="4"
    placeholder="Advice Range"
    class="w-full p-2 border mb-5"
    v-model="form.adviceEn"
  >
  </textarea>

  <div class="flex justify-center">
    <button
      @click="submitRegistration"
      class="bg-[#CC4628] uppercase text-white p-2 rounded-xl group"
    >
      <font-awesome-icon
        icon="fa-solid fa-paper-plane"
        class="mr-1 transition-transform duration-300 group-hover:rotate-45"
      />
      {{ lang === "mn" ? "Илгээх" : "Submit" }}
    </button>
  </div>

  <SuccessModal v-if="success" :success="success" />
  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "YellowCompanyRegistration",
  components: {
    SuccessModal,
    ErrorModal,
  },
  props: {
    approved: Boolean,
  },
  setup(props) {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const success = ref(null);
    const error = ref(null);
    const form = ref({
      nameMn: "",
      nameEn: "",
      professionMn: "",
      professionEn: "",
      phone: "",
      adviceMn: "",
      adviceEn: "",
    });

    // Form reset
    const resetForm = () => {
      form.value = {
        nameMn: "",
        nameEn: "",
        professionMn: "",
        professionEn: "",
        phone: "",
        adviceMn: "",
        adviceEn: "",
      };
    };

    // VALIDATION
    const isValid = () => {
      if (!form.value.nameMn)
        return alert("Байгууллагын нэрийг /монголоор/ оруулна уу");
      if (!form.value.nameEn)
        return alert("Байгууллагын нэрийг /англиар/ оруулна уу");
      if (!form.value.professionMn)
        return alert("Мэргэжлийг /монголоор/ оруулна уу");
      if (!form.value.professionEn)
        return alert("Мэргэжлийг /англиар/ оруулна уу");
      if (!form.value.phone) return alert("Утасны дугаар оруулна уу");
      return true;
    };

    // SUBMIT
    const submitRegistration = async () => {
      if (!isValid()) return;

      form.value.nameMn = form.value.nameMn?.toUpperCase();
      form.value.nameEn = form.value.nameEn?.toUpperCase();

      if (props.approved) {
        form.value.approval = true;
      }

      try {
        const res = await clientYellowInstance.post(
          "/insert/yellow-consultant",
          form.value
        );
        if (res.status === 200) {
          success.value = "Хүсэлт амжилттай илгээгдлээ.";
          resetForm();
          setTimeout(() => {
            success.value = null;
          }, 5000);
        } else {
          error.value = "Алдаа гарлаа.";
          setTimeout(() => {
            error.value = null;
          }, 5000);
        }
      } catch (err) {
        error.value = "Алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 5000);
      }
    };

    return {
      lang,
      form,
      submitRegistration,
      success,
      error,
      resetForm,
    };
  },
};
</script>
