<template>
  <main class="flex justify-center bg-white min-h-[80vh] p-10 font-helvetica">
    <div class="w-full min-[1200px]:w-5/6">
      <!-- breadcrumb nav -->
      <div
        v-motion-fade-visible-once
        :enter="{
          transition: {
            delay: 3000,
            duration: 3000,
          },
        }"
        class="flex gap-1 sm:gap-2 text-sm"
      >
        <router-link
          to="/yellow-page"
          :class="
            isRoute === '/yellow-page' ? 'text-[#CC4628]' : 'text-gray-500'
          "
          class="hover:underline"
          >{{ lang === "mn" ? "Нүүр хуудас" : "Home" }}</router-link
        >
        <span class="text-gray-500">/</span>
        <h6 class="text-gray-500">
          {{ mainRouteName }}
        </h6>

        <span class="text-gray-500">/</span>
        <router-link
          :to="`/yellow-page/${routeId}`"
          :class="
            isRoute === `/yellow-page/${routeId}`
              ? 'text-[#CC4628]'
              : 'text-gray-500'
          "
          class="hover:underline"
          >{{
            lang === "mn"
              ? company.company_info?.translations?.name?.mn
              : company.company_info?.translations?.name?.en
          }}</router-link
        >
      </div>

      <!-- title -->
      <h1
        class="border-l-4 border-[#09396C] text-[#09396C] text-2xl sm:text-3xl md:text-4xl uppercase font-bold pl-3 mt-8"
      >
        {{
          lang === "mn"
            ? company.company_info?.translations?.name?.mn
            : company.company_info?.translations?.name?.en
        }}
      </h1>

      <!-- information -->
      <div class="flex max-sm:flex-col justify-between gap-10">
        <ul
          v-motion-fade-visible-once
          :enter="{
            transition: {
              delay: 3000,
              duration: 3000,
            },
          }"
          class="w-3/4 mt-5 space-y-2"
        >
          <li
            v-for="(contact, index) in company.company_info?.contacts"
            :key="index"
            class="flex items-center"
          >
            <font-awesome-icon
              v-if="contact.contact_type === 'website'"
              icon="fa-solid fa-globe"
              class="text-[#CC4628] mr-2"
            />
            <font-awesome-icon
              v-if="contact.contact_type === 'email'"
              icon="fa-solid fa-envelope"
              class="text-[#CC4628] mr-2"
            />
            <font-awesome-icon
              v-if="contact.contact_type === 'phone'"
              icon="fa-solid fa-phone"
              class="text-[#CC4628] mr-2"
            />
            <font-awesome-icon
              v-if="contact.contact_type === 'fax'"
              icon="fa-solid fa-fax"
              class="text-[#CC4628] mr-2"
            />
            <p class="mr-2">{{ contact.contact_value }}</p>
          </li>

          <li
            v-if="company.company_info?.translations?.address"
            class="flex items-center"
          >
            <font-awesome-icon
              icon="fa-solid fa-location-dot"
              class="text-[#CC4628] mr-2"
            />
            {{
              lang === "mn"
                ? company.company_info?.translations?.address.mn
                : company.company_info?.translations?.address.en
            }}
          </li>
        </ul>

        <div class="flex justify-center w-1/4 mb-3">
          <img
            v-if="company.company_info?.translations?.logo"
            :src="company.company_info?.translations?.logo"
            alt="logo"
            class="object-contain"
          />

          <h1 v-else class="uppercase text-[#CC4628] text-6xl mb-3">ЛОГО</h1>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import { ref, onMounted, computed, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "YellowDetail",
  setup() {
    const route = useRoute();
    const isRoute = computed(() => route.path);
    const routeId = ref(route.params.id);
    const mainRouteId = ref("");

    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const isLoading = ref(false);
    const companyId = ref(route.params.id);
    const company = ref({});

    const fetchCompany = async () => {
      try {
        const res = await clientYellowInstance.get(
          `/company?lang=${lang.value}&id=${companyId.value}`
        );
        if (res.status === 200) {
          company.value = res.data.company;
          mainRouteId.value = res.data.company.category_code
            ? res.data.company.category_code.toString().substring(0, 2)
            : "";
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchCompany();
    });

    const categoryNames = {
      "01": { mn: "Уул уурхайн компани", en: "Mining Companies" },
      "02": { mn: "Ханган нийлүүлэгч", en: "Supplier Companies" },
      "03": { mn: "Үйлчилгээ үзүүлэгч", en: "Service Providers" },
      "04": { mn: "Бусад", en: "Others" },
    };

    const mainRouteName = computed(() => {
      return categoryNames[mainRouteId.value]?.[lang.value] || "";
    });

    watch(
      () => route.params.id,
      (newId) => {
        routeId.value = newId;
        isRoute.value = route.path;
      }
    );

    return {
      companyId,
      isLoading,
      company,
      lang,
      mainRouteId,
      mainRouteName,
      isRoute,
      routeId,
    };
  },
};
</script>
