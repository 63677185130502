<template>
  <main
    class="flex justify-center bg-[#f7f7f7] min-h-screen p-5 md:p-10 font-helvetica"
  >
    <div class="w-full min-[1200px]:w-5/6">
      <!-- breadcrumb nav -->
      <div
        v-motion-fade-visible-once
        :enter="{
          transition: {
            delay: 3000,
            duration: 3000,
          },
        }"
        class="flex gap-1 sm:gap-2 text-sm"
      >
        <router-link to="/yellow-page" class="text-gray-500 hover:underline">{{
          lang === "mn" ? "Нүүр хуудас" : "Home"
        }}</router-link>
        <span class="text-gray-500">/</span>
        <router-link
          to="/yellow-page/register"
          class="text-[#CC4628] hover:underline"
          >{{
            lang === "mn"
              ? "Лавлахад мэдээлэл байршуулах"
              : "Add an organization contact information"
          }}</router-link
        >
      </div>

      <!-- title -->
      <div class="block border-l-4 border-[#09396C] pl-3 py-1 mt-8">
        <h1
          class="text-[#09396C] text-xl md:text-3xl lg:text-4xl uppercase font-extrabold"
        >
          {{ lang === "mn" ? "Мэдээлэл байршуулах" : "Registration" }}
        </h1>
        <p class="text-gray-500 max-sm:text-sm uppercase mt-2">
          {{
            lang === "mn"
              ? "Та байгууллагынхаа мэдээллийг лавлагаа хэсэгт нийтлэх боломжтой. Таны илгээсэн хүсэлтийг админ хянаж зөвшөөрсний дараа таны мэдээллийг олон нийтэд нээлттэй болгоно."
              : "You have the opportunity to publish your organization's information in the reference section. Once your submitted request has been reviewed and approved by the administrator, your information will be made publicly accessible."
          }}
        </p>
      </div>

      <!-- TOGGLE BUTTON -->
      <div class="flex justify-center my-8">
        <div class="flex bg-white rounded-full">
          <button
            @click="selectType(1)"
            class="w-[10rem] sm:w-[15rem] p-1 sm:p-3 max-sm:text-sm uppercase"
            :class="
              selectedType === 1 ? 'bg-[#CC4628] rounded-full text-white' : ''
            "
          >
            {{ lang === "mn" ? "1. Байгууллага" : "1. Company" }}
          </button>
          <button
            @click="selectType(2)"
            class="w-[10rem] sm:w-[15rem] p-1 sm:p-3 max-sm:text-sm uppercase"
            :class="
              selectedType === 2 ? 'bg-[#CC4628] rounded-full text-white' : ''
            "
          >
            {{ lang === "mn" ? "2. Зөвлөх инженер" : "2.Mining consultant" }}
          </button>
        </div>
      </div>

      <!-- FORM -->
      <YellowCompanyRegistration v-if="selectedType === 1" />
      <YellowConsultantRegistration v-else-if="selectedType === 2" />
    </div>
  </main>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import YellowCompanyRegistration from "@/containers/YellowPage/YellowCompanyRegistration.vue";
import YellowConsultantRegistration from "@/containers/YellowPage/YellowConsultantRegistration.vue";

export default {
  name: "YellowRegistration",
  components: {
    YellowCompanyRegistration,
    YellowConsultantRegistration,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const selectedType = ref(1);
    const selectType = (item) => {
      selectedType.value = item;
    };

    return { lang, selectedType, selectType };
  },
};
</script>
