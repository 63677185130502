<template>
  <main class="flex justify-center font-zona">
    <div
      class="flex max-sm:flex-col gap-5 lg:gap-10 w-full min-[1200px]:w-4/5 max-[1199px]:p-5 py-5"
    >
      <div>
        <!-- DOWNLOAD FILES -->
        <div v-if="currentPage === 1" class="w-full mb-10">
          <div
            class="grid grid-cols-1 min-[900px]:grid-cols-2 gap-10 w-full h-fit"
          >
            <div
              v-for="file in files"
              :key="file.id"
              v-motion-slide-visible-once-bottom
              :duration="1000"
              class="bg-gray-100 p-5 space-y-5 shadow"
            >
              <h6 class="font-bold text-sm text-green">
                {{ lang === "mn" ? file.created_at : file.created_at_en }}
              </h6>
              <img
                :src="file.image"
                :alt="file.id"
                class="w-full h-[12rem] object-cover"
              />
              <h1 class="font-bold text-xl line-clamp-2">
                {{ lang === "mn" ? file.title : file.title_en }}
              </h1>
              <button
                @click="downloadFile(file.pdf, file.title)"
                class="bg-green py-2 w-[10rem] rounded-full text-white text-sm uppercase hover:scale-105"
              >
                <font-awesome-icon icon="fa-solid fa-download" class="mr-1" />
                {{ lang === "mn" ? "Татах" : "Download" }}
              </button>
            </div>
          </div>
        </div>

        <!-- NEWS LIST -->
        <div v-if="news.length > 0 && !isLoading" class="w-full">
          <div
            class="grid grid-cols-1 min-[900px]:grid-cols-2 gap-10 w-full h-fit"
          >
            <router-link
              v-for="item in news"
              :key="item.id"
              :to="`/mm/detail/${item.id}`"
              v-motion-slide-visible-once-bottom
              :duration="1000"
              class="bg-gray-100 p-5 space-y-5 shadow cursor-pointer"
            >
              <h6 class="font-bold text-sm text-green">
                {{ item.created_at }}
              </h6>
              <img
                :src="item.image"
                :alt="item.id"
                class="w-full h-[12rem] object-cover"
              />
              <h1 class="font-bold text-xl line-clamp-1">
                {{ lang === "mn" ? item.title : item.title_en }}
              </h1>
              <p class="line-clamp-5 text-sm font-thin leading-6">
                {{ lang === "mn" ? item.summary : item.summary_en }}
              </p>
              <button
                class="bg-green py-2 w-[10rem] rounded-full text-white text-sm uppercase hover:scale-105"
              >
                {{ lang === "mn" ? "Дэлгэрэнгүй" : "Read more" }}
              </button>
            </router-link>
          </div>

          <div class="flex justify-center w-full my-10">
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'green',
                height: 10,
                width: 10,
                fontSize: 'sm',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>
        <div
          v-else-if="news.length === 0 && !isLoading"
          class="flex justify-center items-center w-full h-[10rem] md:text-2xl bg-gray-100"
        >
          {{ lang === "mn" ? "Мэдээ хоосон байна" : "No News Found" }}
        </div>

        <div
          v-else-if="isLoading"
          class="flex justify-center items-center w-full h-[50vh]"
        >
          <SpinLoading />
        </div>
      </div>

      <NewsPageSide />
    </div>
  </main>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import clientInstance from "@/lib/clientInstance";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import NewsPageSide from "@/containers/MiningMongolia/NewsPageSide.vue";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";

export default {
  name: "MMNewsList",
  components: { NewsPageSide, PaginationCart, SpinLoading },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const router = useRouter();
    const route = useRoute();
    const news = ref([]);
    const isLoading = ref(false);
    const currentPage = ref(parseInt(route.params.page) || 1);
    const totalPages = ref(1);

    const fetchData = async (term, page) => {
      try {
        isLoading.value = true;
        let res;

        if (!isNaN(parseFloat(term)) && isFinite(term)) {
          res = await clientInstance.get(
            `/get/news?category=${term}&lang=${
              lang.value
            }&page=${page}&limit=${6}`
          );
        } else if (/^\d{4}-\d{2}$/.test(term)) {
          res = await clientInstance.get(
            `/get/news-by-date?date=${term}&lang=${
              lang.value
            }&page=${page}&limit=${6}`
          );
        } else {
          res = await clientInstance.get(
            `/search/news?term=${term}&lang=${
              lang.value
            }&page=${page}&limit=${6}`
          );
        }

        news.value = res.data.news;
        totalPages.value = res.data.totalPages;
      } catch (err) {
        console.error(err);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData(route.params.cid || 8, route.params.page || 1);
    });

    watch([() => route.params.cid, lang], ([newCid]) => {
      currentPage.value = 1;
      fetchData(newCid || 8);
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchData(route.params.cid, page);
      router.push(`/mm/blog/${route.params.cid}=${currentPage.value}`);
    };

    const files = ref([
      {
        id: 1,
        created_at: "01 Дөрөвдүгээр сар, 2025",
        created_at_en: "01 April 2025",
        title:
          "Уул уурхай, хүнд үйлдвэрийн салбар Монголын эдийн засгийн хөгжилд оруулж буй хувь нэмэр төлөв, чиг хандлага",
        title_en:
          "Mining and manufacturing sectors contribute to Mongolia's economic development, trends",

        image: "https://miningmongolia.mn/static/news/economy.png",
        pdf: "https://miningmongolia.mn/static/pdf/93487230.pdf",
      },
      {
        id: 2,
        created_at: "01 Дөрөвдүгээр сар, 2025",
        created_at_en: "01 April 2025",
        title: "Уул уурхайн салбарын ажиллах хүчний судалгаа",
        title_en: "Mining Sector Labor Force Survey",
        image: "https://miningmongolia.mn/static/news/labor.png",
        pdf: "https://miningmongolia.mn/static/pdf/63132119.pdf",
      },
    ]);

    const downloadFile = (pdfUrl, title) => {
      fetch(pdfUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", title + ".pdf");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => console.error("Error downloading file:", error));
    };

    return {
      lang,
      news,
      isLoading,
      currentPage,
      totalPages,
      onPageChanged,
      files,
      downloadFile,
    };
  },
};
</script>
