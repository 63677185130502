<template>
  <admin-navbar>
    <h1 class="font-bold text-lg">Жагсаалт</h1>

    <div class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">Лавлах дахь инженерийн жагсаалт</h6>
        <router-link
          to="/admin/yellow/add"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Шинээр нэмэх
        </router-link>
      </div>

      <div class="p-3 space-y-3">
        <!-- FILTER -->
        <div class="flex gap-2 bg-gray-200 w-full rounded text-xs p-2">
          <label for="selectedApproval">Нийтлэгдсэн эсэх:</label>
          <select
            name="selectedApproval"
            id="selectedApproval"
            v-model="selectedApproval"
          >
            <option value="all">Бүгд</option>
            <option value="true">Нийтлэгдсэн</option>
            <option value="false">Нийтлэгдээгүй</option>
          </select>
        </div>

        <!-- SEARCH -->
        <div class="flex items-center bg-gray-200 w-full rounded text-xs p-2">
          <label for="searchTerm" class="mr-2">Инженерийн нэр:</label>
          <input
            id="searchTerm"
            name="searchTerm"
            type="text"
            class="rounded p-1 mr-5 w-[20rem] border"
            v-model="searchTerm"
          />

          <button
            @click="fetchDataByName()"
            class="bg-blue-500 p-1 rounded text-white mr-2"
          >
            <font-awesome-icon
              icon="fa-solid fa-magnifying-glass"
              class="mr-1"
            />Хайх
          </button>
          <button
            @click="resetName()"
            class="bg-amber-500 p-1 rounded text-white"
          >
            <font-awesome-icon icon="fa-solid fa-eraser" class="mr-1" />Арилгах
          </button>
        </div>

        <div class="flex justify-between items-center text-xs">
          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="dataPerPage"
              id="dataPerPage"
              class="border rounded p-1 text-blue-500"
              v-model="dataPerPage"
            >
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <span>Нийт инженерийн тоо: {{ totalRequestCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'blue-500',
                height: 7,
                width: 7,
                fontSize: 'xs',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>

        <div v-if="consultants.length > 0 && !isLoading">
          <YellowConsultantTable :data="consultants" @fetchData="fetchData" />
        </div>

        <div
          v-else-if="consultants.length === 0 && !isLoading"
          class="flex justify-center items-center h-[50vh]"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[50vh]">
          <SpinLoading />
        </div>
      </div>
    </div>
  </admin-navbar>

  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref, watch } from "vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import YellowConsultantTable from "@/containers/YellowPage/admin/YellowConsultantTable.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";

export default {
  name: "AdminYellowConsultantUpdate",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    YellowConsultantTable,
    ErrorModal,
  },
  setup() {
    const isLoading = ref(false);
    const consultants = ref([]);
    const dataPerPage = ref(30);
    const totalRequestCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);
    const error = ref(null);
    const selectedApproval = ref("all");

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/yellow-page/get/consultant-list?page=${currentPage.value}&limit=${dataPerPage.value}&approval=${selectedApproval.value}`
        );
        if (res.status === 200) {
          consultants.value = res.data.request;
          totalPages.value = res.data.totalPages;
          totalRequestCount.value = res.data.total;
        }
      } catch (err) {
        error.value = "Жагсаалтыг татахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    watch(selectedApproval, async (newValue, oldValue) => {
      if (newValue !== oldValue) {
        currentPage.value = 1;
        await fetchData();
      }
    });

    watch([dataPerPage], () => {
      currentPage.value = 1;
      fetchData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchData();
    };

    const searchTerm = ref(null);

    const fetchDataByName = async () => {
      try {
        isLoading.value = true;
        currentPage.value = 1;
        selectedApproval.value = "all";

        const res = await adminInstance.get(
          `/yellow-page/get/consultant-list/name?page=${currentPage.value}&limit=${dataPerPage.value}&name=${searchTerm.value}`
        );
        if (res.data && res.status === 200) {
          consultants.value = res.data.request;
          totalPages.value = res.data.totalPages;
          totalRequestCount.value = res.data.total;
        }
      } catch (err) {
        error.value = "Нэрээр хайх явцад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      } finally {
        isLoading.value = false;
      }
    };

    const resetName = () => {
      searchTerm.value = null;
      fetchData();
    };

    return {
      isLoading,
      consultants,
      dataPerPage,
      totalRequestCount,
      totalPages,
      currentPage,
      onPageChanged,
      fetchData,
      searchTerm,
      error,
      fetchDataByName,
      resetName,
      selectedApproval,
    };
  },
};
</script>
