<template>
  <div
    class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[200] h-full w-full"
  >
    <div
      class="h-[90vh] w-3/4 bg-white rounded p-5 space-y-5 overflow-y-scroll"
    >
      <div class="flex justify-end">
        <button
          @click="closeModal()"
          class="flex justify-center items-center bg-blue-200 h-8 w-8 rounded-full text-sm text-white hover:bg-blue-300"
        >
          <font-awesome-icon icon="fa-solid fa-x" />
        </button>
      </div>

      <section class="mt-5">
        <h2 class="text-lg font-bold">Ангилал</h2>

        <div v-if="company?.category" class="grid gap-4 mb-4">
          <div
            v-for="(category, index) in company.category"
            :key="category.category_id"
            class="flex gap-2 items-center"
          >
            <h6>{{ index + 1 }}.</h6>
            <p class="border p-2 w-full">
              Ангиллын код: {{ category.category_code }}
            </p>
            <p class="border p-2 w-full">Ангиллын нэр: {{ category.name }}</p>
            <button
              @click="deleteCategory(category.category_id, index)"
              class="bg-red-500 text-white text-center px-3 py-2 rounded"
            >
              <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
          </div>
        </div>

        <!-- <div
          v-if="newCategory"
          class="grid gap-4"
          :class="newCategory.length > 0 ? 'mb-4' : 'mb-0'"
        >
          <div
            v-for="(category, index) in newCategory"
            :key="index"
            class="flex gap-2 items-center"
          >
            <select v-model="category.category_code" class="border p-2 w-1/4">
              <option value="" disabled>Сонгох</option>
              <option value="0101">Алт</option>
              <option value="0108">Жонш</option>
            </select>
            <input
              v-model="category.name"
              class="border p-2 w-3/4"
              placeholder="Категорийн нэр"
            />
            <button
              @click="removeCategoryField(index)"
              class="bg-red-500 text-white px-3 py-2 rounded"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div> -->

        <div
          v-for="(category, index) in newCategory"
          :key="index"
          class="flex max-sm:flex-col items-center"
          :class="newCategory.length > 0 ? 'mb-4' : 'mb-0'"
        >
          <div class="w-full sm:mr-4">
            <label>Үндсэн ангилал:<span class="text-red-500">*</span></label>
            <select
              v-model="category.mainCategory"
              @change="filterSubCategories(category)"
              class="w-full border p-2"
            >
              <option disabled value="">Үндсэн ангилал</option>
              <option value="01">Уул уурхайн компани</option>
              <option value="02">Ханган нийлүүлэгч</option>
              <option value="03">Үйлчилгээ үзүүлэгч</option>
              <option value="04">Бусад</option>
            </select>
          </div>

          <div class="w-full sm:mr-4">
            <label>Дэд ангилал:<span class="text-red-500">*</span></label>
            <select v-model="category.subCategory" class="w-full border p-2">
              <option disabled value="">Дэд ангилал</option>
              <option
                v-for="sub in category.subCategories"
                :key="sub.id"
                :value="sub.id"
              >
                {{ sub.name }}
              </option>
            </select>
          </div>

          <button
            v-if="newCategory"
            @click="removeCategoryField(index)"
            class="text-red-500 text-xl mt-2"
          >
            -
          </button>
        </div>

        <button
          @click="addCategoryField"
          class="bg-blue-400 text-white p-2 rounded hover:bg-blue-500"
        >
          + Ангилал нэмэх
        </button>

        <h2 class="text-lg font-bold mt-5">Холбоо барих</h2>
        <div v-if="company?.contact" class="grid gap-4">
          <div
            v-for="(contact, index) in company.contact"
            :key="index"
            class="flex gap-2"
          >
            <input
              v-model="contact.contact_type"
              class="border p-2 w-1/4"
              readonly
            />
            <input
              v-model="contact.contact_value"
              class="border p-2 w-3/4"
              placeholder="Мэдээлэл оруулна уу"
            />
            <button
              @click="deleteContact(contact.id, index)"
              class="bg-red-500 text-white text-center px-3 py-1 rounded"
            >
              <font-awesome-icon icon="fa-solid fa-trash" />
            </button>
          </div>
        </div>

        <div v-if="newContact" class="grid gap-4 mt-4">
          <div
            v-for="(contact, index) in newContact"
            :key="index"
            class="flex gap-2 items-center"
          >
            <select v-model="contact.contact_type" class="border p-2 w-1/4">
              <option value="" disabled>Сонгох</option>
              <option value="phone">phone</option>
              <option value="fax">fax</option>
              <option value="email">email</option>
              <option value="website">website</option>
            </select>
            <input
              v-model="contact.contact_value"
              class="border p-2 w-3/4"
              placeholder="Мэдээлэл оруулна уу"
            />
            <button
              @click="removeContactField(index)"
              class="bg-red-500 text-white px-3 py-2 rounded"
            >
              <font-awesome-icon icon="fa-solid fa-xmark" />
            </button>
          </div>
        </div>

        <button
          @click="addContactField"
          class="bg-blue-400 text-white p-2 rounded mt-3 hover:bg-blue-500"
        >
          + Холбоо барих нэмэх
        </button>

        <h2 class="text-lg font-bold mt-5">Компанийн мэдээлэл</h2>
        <div v-if="company?.about" class="grid gap-4">
          <div
            v-for="(about, index) in company.about"
            :key="index"
            class="flex flex-col gap-2 mt-3"
          >
            <h3 class="uppercase text-blue-400 text-base font-bold">
              {{ about.lang === "mn" ? "Монголоор" : "Англиар" }}
            </h3>
            <label class="font-semibold">{{
              about.lang === "mn" ? "Нэр" : "Name"
            }}</label>
            <input
              v-model="about.name"
              class="border p-2"
              placeholder="Компанийн нэр"
            />

            <label class="font-semibold">{{
              about.lang === "mn" ? "Байршил" : "Location"
            }}</label>
            <input
              v-model="about.location"
              class="border p-2"
              placeholder="Байршил"
            />

            <label class="font-semibold">{{
              about.lang === "mn" ? "Хаяг" : "Address"
            }}</label>
            <input
              v-model="about.address"
              class="border p-2"
              placeholder="Хаяг"
            />

            <label class="font-semibold">{{
              about.lang === "mn" ? "Компанийн тухай" : "Company Introduction"
            }}</label>
            <textarea
              v-model="about.about_company"
              class="border p-2"
              placeholder="Компанийн дэлгэрэнгүй мэдээлэл"
            ></textarea>
          </div>

          <label class="font-semibold mt-3">Лого (зураг оруулах)</label>
          <input
            type="file"
            @change="handleFileUpload"
            class="border p-2"
            accept="image/*"
          />

          <div v-if="sharedLogo" class="mt-2">
            <p class="text-sm text-gray-600">Одоогийн лого:</p>
            <img
              :src="sharedLogo"
              alt="Company Logo"
              class="h-20 object-contain border"
            />
          </div>
        </div>

        <div
          v-if="company?.company"
          class="flex justify-center gap-8 mt-8 font-semibold"
        >
          <div class="flex items-center gap-1">
            <input
              type="checkbox"
              name="approval"
              id="approval"
              v-model="company.company.approval"
            />
            <label
              for="approval"
              :class="company.company.approval ? '' : 'text-gray-500'"
              >Нийтлэхийг зөвшөөрсөн эсэх</label
            >
          </div>

          <div class="flex items-center gap-1">
            <input
              type="checkbox"
              name="status"
              id="status"
              v-model="company.company.status"
            />
            <label
              for="status"
              :class="company.company.status ? '' : 'text-gray-500'"
              >Үйл ажиллагаа явуулж байгаа эсэх</label
            >
          </div>
        </div>

        <div class="flex justify-center gap-5 mt-8">
          <button
            @click="updateCompany"
            class="bg-green text-white p-2 rounded"
          >
            <font-awesome-icon icon="fa-solid fa-bookmark" />
            Мэдээллийг хадгалах
          </button>

          <button
            @click="deleteCompany(company.company?.id)"
            class="border border-red-500 p-2 rounded text-red-500 hover:bg-red-500 hover:text-white"
          >
            <font-awesome-icon icon="fa-solid fa-trash" />
            Мэдээллийг устгах
          </button>
        </div>
      </section>
    </div>
  </div>

  <SuccessModal v-if="success" :success="success" />
  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import clientYellowInstance from "@/lib/yellowClientInstance";
import { computed, onMounted, ref, watch } from "vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";

export default {
  name: "YellowEditCompanyModal",
  props: {
    id: Number,
  },
  emits: ["closeModal"],
  components: {
    SuccessModal,
    ErrorModal,
  },
  setup(props, { emit }) {
    const closeModal = () => {
      emit("closeModal");
    };

    const companyId = ref(props.id);
    const company = ref({});
    const success = ref(null);
    const error = ref(null);

    const fetchCompany = async () => {
      try {
        const res = await adminInstance.get(
          `/yellow-page/get/company?id=${companyId.value}`
        );
        if (res.status === 200) {
          company.value = res.data.company;
        }
      } catch (err) {
        error.value = "Байгууллагын мэдээлийг авахад алдаа гарлаа";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const subCategories = ref([]);
    const fetchAllSubCategories = async () => {
      try {
        const res = await clientYellowInstance.get(`/categories?language=mn`);
        if (res.status === 200) {
          subCategories.value = res.data.subCategories;
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(() => {
      fetchCompany();
      fetchAllSubCategories();
    });

    watch(
      () => props.id,
      (newVal) => {
        if (newVal) {
          fetchCompany();
        }
      },
      { immediate: true }
    );

    const sharedLogo = computed({
      get: () => company.value?.about?.[0]?.logo || "",
      set: (newVal) => updateSharedValue("logo", newVal),
    });

    const updateSharedValue = (field, value) => {
      if (company.value?.about) {
        company.value.about.forEach((about) => {
          about[field] = value;
        });
      }
    };

    const handleFileUpload = async (event) => {
      const file = event.target.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append("image", file);

      try {
        const res = await adminInstance.post(
          "/yellow-page/upload/logo",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (res.status === 200 && res.data.fileUrl) {
          updateSharedValue("logo", res.data.fileUrl);
        }
      } catch (err) {
        error.value = "Зураг байршуулахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const newContact = ref([]);
    const addContactField = () => {
      newContact.value.push({ contact_type: "", contact_value: "" });
    };
    const removeContactField = (index) => {
      newContact.value.splice(index, 1);
    };

    const deleteContact = async (contactId, index) => {
      if (!window.confirm(`Мэдээллийг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(
          `/yellow-page/delete/contact?id=${contactId}`
        );
        if (res.status === 200) {
          company.value.contact.splice(index, 1);
          success.value = "Амжилттай устгалаа.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Холбоо барих мэдээллийг устгахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const filterSubCategories = (category) => {
      category.subCategory = "";

      category.subCategories = subCategories.value.filter((sub) =>
        sub.category_code.startsWith(category.mainCategory)
      );
    };

    const newCategory = ref([]);

    const addCategoryField = () => {
      const filteredSubCategories = subCategories.value.filter((sub) =>
        sub.category_code.startsWith("01")
      );

      newCategory.value.push({
        mainCategory: "01",
        subCategory: null,
        subCategories: filteredSubCategories,
      });
    };

    const removeCategoryField = (index) => {
      newCategory.value.splice(index, 1);
    };

    const deleteCategory = async (categoryId, index) => {
      if (!window.confirm(`Энэ категори устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(
          `/yellow-page/delete/category?category_id=${categoryId}&company_id=${companyId.value}`
        );
        if (res.status === 200) {
          company.value.category.splice(index, 1);
          success.value = "Амжилттай устгалаа.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Категорийг устгахад алдаа гарлаа";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const updateCompany = async () => {
      try {
        const res = await adminInstance.post("/yellow-page/update/company", {
          company: company.value,
          new_contact: newContact.value,
          new_category: newCategory.value,
        });
        if (res.status === 200) {
          success.value = "Амжилттай шинэчлэгдлээ.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Мэдээллийг хадгалахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const deleteCompany = async (id) => {
      try {
        if (!window.confirm(`Байгууллагын мэдээллийг устгах уу?`)) {
          return;
        }

        const res = await adminInstance.delete(
          `/yellow-page/delete/company?id=${id}`
        );
        if (res.status === 200) {
          success.value = "Амжилттай устгалаа.";
          setTimeout(() => {
            success.value = null;
          }, 3000);
          closeModal();
        }
      } catch (err) {
        error.value = "Мэдээллийг устгахад алдаа гарлаа";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    return {
      closeModal,
      companyId,
      company,
      success,
      error,
      updateCompany,
      sharedLogo,
      updateSharedValue,
      handleFileUpload,
      addContactField,
      removeContactField,
      newContact,
      deleteContact,
      deleteCompany,
      newCategory,
      addCategoryField,
      removeCategoryField,
      deleteCategory,
      filterSubCategories,
    };
  },
};
</script>
