<template>
  <div class="bg-white rounded shadow">
    <div
      class="flex justify-between border-t-4 border-t-blue-500 rounded-t border-b p-3"
    >
      <h6 class="text-base">{{ page_title }}</h6>
      <button
        v-if="data"
        @click="cancelEdit()"
        class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />
        Буцах
      </button>
    </div>

    <div class="p-3 space-y-5">
      <div class="flex">
        <h4 class="w-[15rem] font-bold">
          Нүүр зураг<span class="text-red-500">*</span>
        </h4>
        <div class="space-y-3 w-full">
          <div
            class="flex flex-col justify-center items-center"
            :class="mainImage ? 'w-3/4' : 'h-40 w-40'"
          >
            <img
              :src="imageSrc"
              alt="image"
              :class="!mainImage ? 'w-14 h-14' : ''"
            />
            <span v-if="!mainImage" class="text-xs text-gray-500"
              >Зураг байхгүй байна</span
            >
          </div>
          <input
            type="file"
            class="w-full border border-gray-300 rounded p-2"
            @change="onImageChange"
            accept="image/jpeg, image/png"
          />
          <span class="text-sky-500 text-xs">
            730X455 (Pixel) хэмжээтэй зураг оруулвал тохиромжтой. Зөвхөн jpg,
            jpeg, png өргөтгөлтэй зураг оруулна уу.
          </span>
        </div>
      </div>

      <div class="flex items-center">
        <h4 class="w-[15rem] font-bold">
          Ангилал<span class="text-red-500">*</span>
        </h4>
        <select
          name="news_category"
          id="news_category"
          class="w-full border border-gray-300 rounded p-2"
          v-model="category_cid"
        >
          <option disabled selected value="">Төрөл</option>
          <option v-for="cat in categories" :key="cat.cid" :value="cat.cid">
            {{ cat.category }}
          </option>
        </select>
      </div>

      <!-- NEWS CONTEXT -->
      <div class="space-y-1">
        <button
          @click="toggleMongolianNews()"
          class="flex justify-between items-center p-3 w-full bg-sky-500 text-white"
        >
          <span>Монгол хэлээр</span>
          <font-awesome-icon
            v-if="isMongolianNews"
            icon="fa-solid fa-angle-up"
          />
          <font-awesome-icon v-else icon="fa-solid fa-angle-down" />
        </button>

        <div v-if="isMongolianNews" class="space-y-5 py-5">
          <div class="flex items-center">
            <h4 class="w-[15rem] font-bold">
              Гарчиг<span class="text-red-500">*</span>
              <span class="text-gray-400 text-xs font-thin"> /монголоор/</span>
            </h4>
            <input
              type="text"
              class="w-full border border-gray-300 rounded p-2"
              placeholder="Гарчиг"
              v-model="title"
            />
          </div>

          <div class="flex">
            <h4 class="w-[15rem] font-bold">
              Товч агуулга<span class="text-red-500">*</span>
              <span class="text-gray-400 text-xs font-thin"> /монголоор/</span>
            </h4>
            <textarea
              name="summary"
              id="summary"
              rows="6"
              class="w-full min-h-[5rem] border border-gray-300 rounded p-2"
              placeholder="Товч агуулга"
              v-model="summary"
            >
            </textarea>
          </div>

          <div class="flex">
            <h4 class="w-60 font-bold">
              Ерөнхий агуулга<span class="text-red-500">*</span>
              <span class="text-gray-400 text-xs font-thin"> /монголоор/</span>
            </h4>
            <div class="prose" style="max-width: 900px">
              <ckeditor
                :editor="Editor"
                v-model="editorData"
                :config="editorConfig"
              />
            </div>
          </div>
        </div>

        <button
          @click="toggleEnglishNews()"
          class="flex justify-between items-center p-3 w-full bg-blue-500 text-white"
        >
          <span>Англи хэлээр</span>
          <font-awesome-icon v-if="isEnglishNews" icon="fa-solid fa-angle-up" />
          <font-awesome-icon v-else icon="fa-solid fa-angle-down" />
        </button>

        <div v-if="isEnglishNews" class="space-y-5 py-5">
          <div class="flex items-center">
            <h4 class="w-[15rem] font-bold">
              Гарчиг
              <span class="text-red-500">*</span>
              <span class="text-gray-400 text-xs font-thin"> /англиар/</span>
            </h4>
            <input
              type="text"
              class="w-full border border-gray-300 rounded p-2"
              placeholder="Title"
              v-model="title_en"
            />
          </div>

          <div class="flex">
            <h4 class="w-[15rem] font-bold">
              Товч агуулга<span class="text-red-500">*</span>
              <span class="text-gray-400 text-xs font-thin"> /англиар/</span>
            </h4>
            <textarea
              name="summary_en"
              id="summary_en"
              rows="6"
              class="w-full min-h-[5rem] border border-gray-300 rounded p-2"
              placeholder="Summary"
              v-model="summary_en"
            >
            </textarea>
          </div>

          <div class="flex">
            <h4 class="w-[15rem] font-bold">
              Ерөнхий агуулга<span class="text-red-500">*</span>
              <span class="text-gray-400 text-xs font-thin"> /англиар/</span>
            </h4>
            <div class="flex justify-start w-full">
              <div class="prose" style="max-width: 900px">
                <ckeditor
                  :editor="Editor"
                  v-model="editorData_en"
                  :config="editorConfig"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex items-center">
        <h4 class="w-[15rem] font-bold">
          Нийтлэгч<span class="text-red-500">*</span>
        </h4>
        <input
          type="text"
          class="w-full border border-gray-300 rounded p-2"
          placeholder="Нийтлэлийг бичсэн ..."
          v-model="publisher"
        />
      </div>

      <div class="flex items-center">
        <h4 class="w-[15rem] font-bold">
          Лавлах сайт дээр <br />
          нийтлэх эсэх<span class="text-red-500">*</span>
        </h4>
        <select
          name="yellow_book"
          id="yellow_book"
          class="w-full border border-gray-300 rounded p-2"
          v-model="yellow_book"
        >
          <option value="true">Тийм</option>
          <option value="false">Үгүй</option>
        </select>
      </div>

      <div class="flex">
        <h4 class="w-[15rem] font-bold">
          Төлөв<span class="text-red-500">*</span>
        </h4>

        <div class="w-full">
          <select
            name="news_status"
            id="news_status"
            class="w-full border border-gray-300 rounded p-2"
            v-model="news_status"
          >
            <option value="true">Идэвхитэй</option>
            <option value="false">Идэвхигүй</option>
          </select>
          <button
            @click="uploadNews()"
            class="bg-blue-500 p-2 rounded text-white mt-5 hover:bg-blue-700"
          >
            {{ button_text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import Editor from "ckeditor5-custom-build/build/ckeditor";
import { useStore } from "vuex";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "MMAddNews",
  props: {
    page_title: {
      type: String,
      default: "Мэдээ",
    },
    data: {
      type: Object,
    },
    button_text: {
      type: String,
      default: "Хадгалах",
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    // Main image
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        if (props.data.image) {
          imageSrc.value = props.data.image;
          mainImage.value = props.data.image;
        } else {
          imageSrc.value = defaultImage;
          mainImage.value = null;
        }
      }
    };

    const title = ref("");
    const title_en = ref("");
    const categories = computed(() => store.getters["admin/getNewsCategory"]);
    const category_cid = ref(null);
    const summary = ref("");
    const summary_en = ref("");
    const editorData = ref("");
    const editorData_en = ref("");
    const publisher = ref("Админ");
    const news_status = ref(true);
    const yellow_book = ref(false);

    onMounted(async () => {
      try {
        await store.dispatch("admin/fetchNewsCategory");
        if (props.data) {
          imageSrc.value = props.data.image;
          mainImage.value = props.data.image;
          title.value = props.data.title;
          title_en.value = props.data.title_en;
          category_cid.value = props.data.category_cid;
          summary.value = props.data.summary;
          summary_en.value = props.data.summary_en;
          editorData.value = props.data.text;
          editorData_en.value = props.data.text_en;
          publisher.value = props.data.publisher;
          news_status.value = props.data.news_status;
          yellow_book.value = props.data.yellow_book;
        }
      } catch (err) {
        console.error(err);
      }
    });

    // Form reset
    const resetForm = () => {
      imageSrc.value = defaultImage;
      mainImage.value = "";
      title.value = "";
      title_en.value = "";
      category_cid.value = "";
      summary.value = "";
      summary_en.value = "";
      editorData.value = "";
      editorData_en.value = "";
      publisher.value = "Админ";
      news_status.value = true;
      yellow_book.value = false;
    };

    const cancelEdit = () => {
      resetForm();
      emit("cancel-edit");
    };

    /* CKEDITOR */
    class MyUploadAdapter {
      constructor(loader) {
        this.loader = loader;
      }

      upload() {
        return this.loader.file.then(
          (file) =>
            new Promise((resolve, reject) => {
              this._initRequest();
              this._initListeners(resolve, reject, file);
              this._sendRequest(file);
            })
        );
      }

      abort() {
        if (this.xhr) {
          this.xhr.abort();
        }
      }

      _initRequest() {
        const xhr = (this.xhr = new XMLHttpRequest());
        xhr.open("POST", "https://miningmongolia.mn/admin/ckupload", true);
        xhr.responseType = "json";
      }

      _initListeners(resolve, reject, file) {
        const xhr = this.xhr;
        const loader = this.loader;
        const genericErrorText = `Couldn't upload file: ${file.name}.`;

        xhr.addEventListener("error", () => reject(genericErrorText));
        xhr.addEventListener("abort", () => reject());
        xhr.addEventListener("load", () => {
          const response = xhr.response;

          if (!response || response.error) {
            return reject(
              response && response.error
                ? response.error.message
                : genericErrorText
            );
          }

          resolve({
            default: response.url[0],
          });
        });

        if (xhr.upload) {
          xhr.upload.addEventListener("progress", (evt) => {
            if (evt.lengthComputable) {
              loader.uploadTotal = evt.total;
              loader.uploaded = evt.loaded;
            }
          });
        }
      }

      _sendRequest(file) {
        const data = new FormData();
        data.append("image", file);
        this.xhr.send(data);
      }
    }

    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader);
      };
    }

    const editorConfig = {
      extraPlugins: [MyCustomUploadAdapterPlugin],
      removePlugins: ["MediaEmbedToolbar", "Markdown"],
      alignment: {
        options: ["left", "right", "center", "justify"],
      },
      language: "en",
      licenseKey: "",
    };

    // News by mongolian
    const isMongolianNews = ref(false);
    const toggleMongolianNews = () => {
      isMongolianNews.value = !isMongolianNews.value;
    };

    // News by english
    const isEnglishNews = ref(false);
    const toggleEnglishNews = () => {
      isEnglishNews.value = !isEnglishNews.value;
    };

    // VALIDATION
    const isValid = () => {
      if (!mainImage.value) {
        return alert("Нүүр зураг оруулна уу");
      }
      if (title.value || summary.value || editorData.value) {
        if (!title.value) return alert("Монгол мэдээний гарчиг оруулна уу");
        if (!summary.value)
          return alert("Монгол мэдээний товч агуулгыг оруулна уу");
        if (!editorData.value)
          return alert("Монгол мэдээний ерөнхий агуулгыг оруулна уу");
      }
      if (title_en.value || summary_en.value || editorData_en.value) {
        if (!title_en.value) return alert("Англи мэдээний гарчиг оруулна уу");
        if (!summary_en.value)
          return alert("Англи мэдээний товч агуулгыг оруулна уу");
        if (!editorData_en.value)
          return alert("Англи мэдээний ерөнхий агуулгыг оруулна уу");
      }

      if (
        (!title.value || title.value.trim() === "") &&
        (!summary.value || summary.value.trim() === "") &&
        (!editorData.value || editorData.value.trim() === "") &&
        (!title_en.value || title_en.value.trim() === "") &&
        (!summary_en.value || summary_en.value.trim() === "") &&
        (!editorData_en.value || editorData_en.value.trim() === "")
      ) {
        return alert("Мэдээний агуулгыг оруулна уу");
      }

      if (!category_cid.value) return alert("Төрөл сонгоно уу");
      if (!publisher.value) return alert("Нийтлэгчийг оруулна уу");
      if (!news_status.value) return alert("Мэдээний төлвийг сонгоно уу");
      if (!yellow_book.value)
        return alert("Лавлах сайт дээр нийтлэх эсэхийг сонгоно уу");
      return true;
    };

    // SUBMIT
    const uploadNews = async () => {
      if (!isValid()) return;

      const formData = new FormData();
      formData.append("image", mainImage.value);
      formData.append("title", title.value);
      formData.append("title_en", title_en.value);
      formData.append("category_cid", category_cid.value);
      formData.append("summary", summary.value);
      formData.append("summary_en", summary_en.value);
      formData.append("text", editorData.value);
      formData.append("text_en", editorData_en.value);
      formData.append("publisher", publisher.value);
      formData.append("news_status", news_status.value);
      formData.append("yellow_book", yellow_book.value);

      try {
        if (!props.data) {
          const res = await adminInstance.post("/insert/news", formData);
          if (res.status === 200) window.location.reload();
        } else {
          formData.append("id", props.data.id);
          const res = await adminInstance.post("/update/news", formData);
          if (res.status === 200) window.location.reload();
        }
      } catch (err) {
        return err;
      }
    };

    return {
      categories,
      imageSrc,
      mainImage,
      onImageChange,
      title,
      title_en,
      category_cid,
      summary,
      summary_en,
      Editor,
      editorData,
      editorData_en,
      editorConfig,
      publisher,
      news_status,
      uploadNews,
      cancelEdit,
      isMongolianNews,
      toggleMongolianNews,
      isEnglishNews,
      toggleEnglishNews,
      yellow_book,
    };
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 500px;
}
</style>
