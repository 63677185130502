<template>
  <!-- SIDE LIST -->
  <div class="w-full sm:w-[30rem] space-y-7 font-zona">
    <!-- search -->
    <side-container :title="'Хайлт'" :title_en="'Search'">
      <form action="" class="flex">
        <input
          name="keyWord"
          id="keyWord"
          type="text"
          :placeholder="
            lang === 'mn' ? 'Хайлт хийх ......' : 'Search news ......'
          "
          class="p-2 bg-blue-100 text-center text-blue-400 placeholder:text-blue-400 placeholder:text-xs"
          v-model="keyWord"
        />
        <router-link
          :to="`/mm/blog/${searchTerm}=${1}`"
          class="flex justify-center items-center w-10 h-10 bg-green text-white"
        >
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </router-link>
      </form>
    </side-container>

    <!-- categories -->
    <side-container :title="'Ангилал'" :title_en="'Categories'">
      <div class="w-full space-y-3">
        <router-link
          v-for="cat in categories"
          :key="cat.cid"
          :to="`/mm/blog/${cat.cid}=${1}`"
          class="w-full flex text-white text-sm"
        >
          <span class="flex items-center w-4/5 bg-green p-2">{{
            lang === "mn" ? cat.category : cat.category_en
          }}</span>
          <span
            class="flex justify-center items-center w-1/5 bg-lightGreen p-2"
            >{{ cat.news_count }}</span
          >
        </router-link>
      </div>
    </side-container>

    <!-- recent news -->
    <side-container :title="'Шинэ мэдээ'" :title_en="'Recent News'">
      <ul v-if="recentNews.length > 0">
        <li v-for="(news, index) in recentNews" :key="index">
          <router-link
            :to="`/mm/detail/${news.id}`"
            class="flex gap-3 py-5 border-b border-blue-100"
          >
            <img
              :src="news.image"
              :alt="news.id"
              class="w-20 object-cover object-center"
            />
            <div>
              <span class="line-clamp-2 text-sm hover:underline">{{
                lang === "mn" ? news.title : news.title_en
              }}</span>
              <div class="flex items-center gap-2 text-[#69a1bb] text-xs mt-2">
                <font-awesome-icon icon="fa-solid fa-calendar-days" />
                {{ news.created_at }}
              </div>
            </div>
          </router-link>
        </li>
      </ul>

      <p v-else class="text-center my-5">
        {{ lang === "mn" ? "Мэдээ хоосон байна" : "No Recent News" }}
      </p>
    </side-container>

    <!-- архив -->
    <side-container :title="'Архив'" :title_en="'Timeline'">
      <div class="space-y-3 w-full text">
        <div
          v-for="date in archiveDates"
          :key="date.created_at"
          class="bg-gray-100 w-full p-2 text-gray-500 text-center uppercase text-sm"
        >
          <router-link :to="`/mm/blog/${date.created_at}=${1}`">
            {{ date.date }}
          </router-link>
        </div>
      </div>
    </side-container>

    <!-- other social links -->
    <side-container :title="'Бусад сувгууд'" :title_en="'Socials'">
      <div class="flex items-center gap-2">
        <a
          v-for="link in socialLinks"
          :key="link.id"
          :href="link.link"
          target="_blank"
          class="flex items-center justify-center bg-green w-10 h-10 text-white"
        >
          <font-awesome-icon :icon="link.icon" />
        </a>
      </div>
    </side-container>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch } from "vue";
import clientInstance from "@/lib/clientInstance";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import SideContainer from "@/components/MiningMongolia/SideContainer.vue";

export default {
  name: "NewsPageSide",
  components: {
    SideContainer,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const route = useRoute();
    const keyWord = ref("");
    const categories = ref([]);
    const recentNews = computed(() => store.getters["client/getRecentNews"]);
    const archiveDates = ref([]);
    const socialLinks = computed(() => store.getters["client/getSocialLinks"]);
    const isLoading = ref(true);

    const searchTerm = computed(() => (keyWord.value ? keyWord.value : 8));

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const catRes = await clientInstance.get(
          `/get/category-news-count?type=${"news"}`
        );
        categories.value = catRes.data.categories;
        const dateRes = await clientInstance.get(
          `/get/archive-news-date?lang=${lang.value}`
        );
        archiveDates.value = dateRes.data.dates;
      } catch (err) {
        console.error(err);
      }
    };

    onMounted(async () => {
      try {
        await store.dispatch("client/fetchRecentNews", lang.value);
        await store.dispatch("client/fetchSocialLinks");
        await fetchData();
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    });

    watch([() => route.params.cid, lang], async () => {
      await fetchData();
      await store.dispatch("client/fetchRecentNews", lang.value);
    });

    return {
      lang,
      categories,
      recentNews,
      keyWord,
      searchTerm,
      archiveDates,
      socialLinks,
      isLoading,
    };
  },
};
</script>
