<template>
  <p class="text-gray-500 text-sm italic">
    <span class="text-red-500">*</span> -
    {{ lang === "mn" ? "Заавал бөглөх" : "Must be filled" }}
  </p>

  <div
    v-for="(category, index) in form.categories"
    :key="index"
    class="flex max-sm:flex-col items-center mt-5"
  >
    <div class="w-full sm:mr-10">
      <label
        >{{ lang === "mn" ? "Үндсэн ангилал:" : "Main category:"
        }}<span class="text-red-500">*</span></label
      >
      <select
        v-model="category.mainCategory"
        @change="filterSubCategories(category)"
        class="w-full border p-2"
      >
        <option disabled value="">
          {{ lang === "mn" ? "Үндсэн ангилал" : "All categories" }}
        </option>
        <option value="01">
          {{ lang === "mn" ? "Уул уурхайн компани" : "Mining Companies" }}
        </option>
        <option value="02">
          {{ lang === "mn" ? "Ханган нийлүүлэгч" : "Supplier Companies" }}
        </option>
        <option value="03">
          {{ lang === "mn" ? "Үйлчилгээ үзүүлэгч" : "Service Companies" }}
        </option>
        <option value="04">
          {{ lang === "mn" ? "Бусад" : "Other Companies" }}
        </option>
      </select>
    </div>

    <div class="w-full sm:mr-3">
      <label
        >{{ lang === "mn" ? "Дэд ангилал:" : "Sub category:"
        }}<span class="text-red-500">*</span></label
      >
      <select v-model="category.subCategory" class="w-full border p-2">
        <option disabled value="">
          {{ lang === "mn" ? "Дэд ангилал" : "All sub-categories" }}
        </option>
        <option
          v-for="sub in category.subCategories"
          :key="sub.id"
          :value="sub.id"
        >
          {{ sub.name }}
        </option>
      </select>
    </div>

    <button
      v-if="form.categories.length > 1"
      @click="removeCategory(index)"
      class="text-red-500 text-xl mt-2"
    >
      -
    </button>
  </div>

  <button @click="addCategory" class="text-blue-500 text-sm mb-5 mt-2">
    <font-awesome-icon icon="fa-solid fa-plus" />
    {{ lang === "mn" ? "Ангилал нэмэх" : "Add Category" }}
  </button>

  <div class="flex max-sm:flex-col gap-5 md:gap-10 w-full">
    <div class="w-full">
      <label for="nameMn"
        >{{
          lang === "mn"
            ? "Байгууллагын нэр /монголоор/:"
            : "Company Name by Mongolian:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="nameMn"
        name="nameMn"
        type="text"
        placeholder="Байгууллагын нэр"
        class="w-full p-2 border"
        v-model="form.nameMn"
      />
    </div>

    <div class="w-full">
      <label for="nameEn"
        >{{
          lang === "mn"
            ? "Байгууллагын нэр /англиар/:"
            : "Company Name by English:"
        }}<span class="text-red-500">*</span></label
      >
      <input
        id="nameEn"
        name="nameEn"
        type="text"
        placeholder="Company name"
        class="w-full p-2 border"
        v-model="form.nameEn"
      />
    </div>
  </div>

  <div class="flex max-sm:flex-col justify-between gap-5 md:gap-10 mt-5">
    <div class="w-full">
      <label>{{ lang === "mn" ? "И-мэйл хаяг:" : "Email address:" }}</label>
      <div
        v-for="(email, index) in form.emails"
        :key="'email' + index"
        class="flex gap-2 mb-2"
      >
        <input
          v-model="form.emails[index]"
          type="email"
          class="w-full p-2 border"
          :placeholder="lang === 'mn' ? 'И-мэйл' : 'E-mail'"
        />
        <button
          v-if="form.emails.length > 1"
          @click="removeField('emails', index)"
          class="text-red-500"
        >
          -
        </button>
      </div>
      <button @click="addField('emails')" class="text-blue-500 text-sm">
        <font-awesome-icon icon="fa-solid fa-plus" />
        {{ lang === "mn" ? "И-мэйл нэмэх" : "Add Email" }}
      </button>
    </div>

    <div class="w-full">
      <label>{{ lang === "mn" ? "Утасны дугаар:" : "Phone number:" }}</label>
      <div
        v-for="(phone, index) in form.phones"
        :key="'phone' + index"
        class="flex gap-2 mb-2"
      >
        <input
          v-model="form.phones[index]"
          type="tel"
          class="w-full p-2 border"
          :placeholder="lang === 'mn' ? 'Утас' : 'Phone Number'"
        />
        <button
          v-if="form.phones.length > 1"
          @click="removeField('phones', index)"
          class="text-red-500"
        >
          -
        </button>
      </div>
      <button @click="addField('phones')" class="text-blue-500 text-sm">
        <font-awesome-icon icon="fa-solid fa-plus" />
        {{ lang === "mn" ? "Утасны дугаар нэмэх" : "Add Phone" }}
      </button>
    </div>
  </div>

  <div class="flex max-sm:flex-col justify-between gap-5 md:gap-10 my-5">
    <!-- Faxes -->
    <div class="w-full">
      <label>{{ lang === "mn" ? "Факс:" : "Fax:" }}</label>
      <div
        v-for="(fax, index) in form.faxes"
        :key="'fax' + index"
        class="flex gap-2 mb-2"
      >
        <input
          v-model="form.faxes[index]"
          type="tel"
          class="w-full p-2 border"
          :placeholder="lang === 'mn' ? 'Факс' : 'Fax'"
        />
        <button
          v-if="form.faxes.length > 1"
          @click="removeField('faxes', index)"
          class="text-red-500"
        >
          -
        </button>
      </div>
      <button @click="addField('faxes')" class="text-blue-500 text-sm">
        <font-awesome-icon icon="fa-solid fa-plus" />
        {{ lang === "mn" ? "Факс нэмэх" : "Add Fax" }}
      </button>
    </div>

    <!-- Websites -->
    <div class="w-full">
      <label>{{ lang === "mn" ? "Вэбсайт:" : "Website:" }}</label>
      <div
        v-for="(website, index) in form.websites"
        :key="'website' + index"
        class="flex gap-2 mb-2"
      >
        <input
          v-model="form.websites[index]"
          type="url"
          class="w-full p-2 border"
          :placeholder="lang === 'mn' ? 'Вэбсайт' : 'Website'"
        />
        <button
          v-if="form.websites.length > 1"
          @click="removeField('websites', index)"
          class="text-red-500"
        >
          -
        </button>
      </div>
      <button @click="addField('websites')" class="text-blue-500 text-sm">
        <font-awesome-icon icon="fa-solid fa-plus" />
        {{ lang === "mn" ? "Вэбсайт нэмэх" : "Add Website" }}
      </button>
    </div>
  </div>

  <div class="flex max-sm:flex-col my-5">
    <h4 class="w-[15rem]">Лого:<span class="text-red-500">*</span></h4>
    <div class="space-y-3 w-full">
      <div
        class="flex flex-col justify-center items-center"
        :class="mainImage ? 'w-1/3 object-contain' : 'h-40 w-40'"
      >
        <img
          :src="imageSrc"
          alt="image"
          :class="!mainImage ? 'w-14 h-14' : ''"
        />
        <span v-if="!mainImage" class="text-xs text-gray-500"
          >Зураг байхгүй байна</span
        >
      </div>
      <input
        type="file"
        class="w-full bg-white border p-2"
        @change="onImageChange"
        accept="image/jpeg, image/png"
      />
    </div>
  </div>

  <label for="addressMn" class="mt-5">{{
    lang === "mn" ? "Хаяг /монголоор/:" : "Address by mongolian:"
  }}</label>
  <input
    id="addressMn"
    name="addressMn"
    type="text"
    placeholder="Хаяг"
    class="w-full p-2 border mb-5"
    v-model="form.addressMn"
  />

  <label for="addressEn">{{
    lang === "mn" ? "Хаяг /англиар/:" : "Address by english:"
  }}</label>
  <input
    id="addressEn"
    name="addressEn"
    type="text"
    placeholder="Address"
    class="w-full p-2 border mb-5"
    v-model="form.addressEn"
  />

  <label for="aboutMn" class="mt-5">{{
    lang === "mn"
      ? "Товч танилцуулга /монголоор/:"
      : "Introduction by mongolian:"
  }}</label>
  <textarea
    id="aboutMn"
    name="aboutMn"
    type="text"
    rows="6"
    placeholder="Танилцуулга"
    class="w-full p-2 border mb-5"
    v-model="form.aboutMn"
  >
  </textarea>

  <label for="aboutEn" class="mt-5">{{
    lang === "mn" ? "Товч танилцуулга /англиар/:" : "Introduction by english:"
  }}</label>
  <textarea
    id="aboutEn"
    name="aboutEn"
    type="text"
    rows="6"
    placeholder="Introduction"
    class="w-full p-2 border mb-5"
    v-model="form.aboutEn"
  >
  </textarea>

  <div class="flex justify-center">
    <button
      @click="submitRegistration"
      class="bg-[#CC4628] uppercase text-white p-2 rounded-xl group"
    >
      <font-awesome-icon
        icon="fa-solid fa-paper-plane"
        class="mr-1 transition-transform duration-300 group-hover:rotate-45"
      />
      {{ lang === "mn" ? "Илгээх" : "Submit" }}
    </button>
  </div>

  <SuccessModal v-if="success" :success="success" />
  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import SuccessModal from "@/components/ui/SuccessModal.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "YellowCompanyRegistration",
  components: {
    SuccessModal,
    ErrorModal,
  },
  props: {
    approved: Boolean,
  },
  setup(props) {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const subCategories = ref([]);
    const success = ref(null);
    const error = ref(null);
    const form = ref({
      categories: [{ mainCategory: "01", subCategory: "", subCategories: [] }],
      nameMn: "",
      nameEn: "",
      emails: [""],
      phones: [""],
      faxes: [""],
      websites: [""],
      addressMn: "",
      addressEn: "",
      aboutMn: "",
      aboutEn: "",
    });

    // Main image
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        imageSrc.value = defaultImage;
        mainImage.value = null;
      }
    };

    const fetchAllSubCategories = async () => {
      try {
        const res = await clientYellowInstance.get(
          `/categories?language=${lang.value}`
        );
        if (res.status === 200) {
          subCategories.value = res.data.subCategories;
          filterSubCategories(form.value.categories[0]);
        }
      } catch (err) {
        return err;
      }
    };

    onMounted(() => {
      fetchAllSubCategories();
    });

    // Form reset
    const resetForm = () => {
      imageSrc.value = defaultImage;
      mainImage.value = "";
      form.value = {
        categories: [
          { mainCategory: "01", subCategory: "", subCategories: [] },
        ],
        nameMn: "",
        nameEn: "",
        emails: [""],
        phones: [""],
        faxes: [""],
        websites: [""],
        addressMn: "",
        addressEn: "",
        aboutMn: "",
        aboutEn: "",
      };
    };

    const addField = (field) => {
      form.value[field].push("");
    };

    const removeField = (field, index) => {
      form.value[field].splice(index, 1);
    };

    const addCategory = () => {
      const filteredSubCategories = subCategories.value.filter((sub) =>
        sub.category_code.startsWith("01")
      );

      form.value.categories.push({
        mainCategory: "01",
        subCategory: "",
        subCategories: filteredSubCategories,
      });
    };

    const removeCategory = (index) => {
      form.value.categories.splice(index, 1);
    };

    const filterSubCategories = (category) => {
      category.subCategory = "";

      category.subCategories = subCategories.value.filter((sub) =>
        sub.category_code.startsWith(category.mainCategory)
      );
    };

    // VALIDATION
    const isValid = () => {
      if (form.value.categories) {
        for (let category of form.value.categories) {
          if (!category.subCategory) {
            return alert(`Дэд ангиллыг сонгоно уу.`);
          }
        }
      }
      if (!mainImage.value) {
        return alert("Лого оруулна уу");
      }
      if (!form.value.nameMn)
        return alert("Байгууллагын нэрийг /монголоор/ оруулна уу");
      if (!form.value.nameEn)
        return alert("Байгууллагын нэрийг /англиар/ оруулна уу");

      return true;
    };

    // SUBMIT
    const submitRegistration = async () => {
      if (!isValid()) return;

      form.value.nameMn = form.value.nameMn?.toUpperCase();
      form.value.nameEn = form.value.nameEn?.toUpperCase();

      const formData = new FormData();
      formData.append("categories", JSON.stringify(form.value.categories));
      formData.append("image", mainImage.value);
      formData.append("nameMn", form.value.nameMn);
      formData.append("nameEn", form.value.nameEn);
      formData.append("emails", form.value.emails);
      formData.append("phones", form.value.phones);
      formData.append("faxes", form.value.faxes);
      formData.append("websites", form.value.websites);
      formData.append("addressMn", form.value.addressMn);
      formData.append("addressEn", form.value.addressEn);
      formData.append("aboutMn", form.value.aboutMn);
      formData.append("aboutEn", form.value.aboutEn);
      if (props.approved) {
        formData.append("approval", true);
      }

      try {
        const res = await clientYellowInstance.post(
          "/insert/yellow-registration",
          formData
        );
        if (res.status === 200) {
          success.value = "Хүсэлт амжилттай илгээгдлээ.";
          resetForm();
          setTimeout(() => {
            success.value = null;
          }, 5000);
        } else {
          error.value = "Алдаа гарлаа.";
          setTimeout(() => {
            error.value = null;
          }, 5000);
        }
      } catch (err) {
        error.value = "Алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 5000);
      }
    };

    return {
      lang,
      form,
      onImageChange,
      imageSrc,
      mainImage,
      submitRegistration,
      success,
      error,
      subCategories,
      addField,
      removeField,
      resetForm,
      addCategory,
      removeCategory,
      filterSubCategories,
    };
  },
};
</script>
