<template>
  <admin-navbar>
    <h1 class="font-bold text-lg">Жагсаалт</h1>

    <div class="bg-white rounded shadow">
      <div
        class="flex justify-between items-center border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">Лавлах дахь байгууллагын жагсаалт</h6>
        <router-link
          to="/admin/yellow/add"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Шинээр нэмэх
        </router-link>
      </div>

      <div class="p-3 space-y-3">
        <!-- FILTER -->
        <div class="flex flex-col gap-2 bg-gray-200 w-full rounded text-xs p-2">
          <div class="flex items-center">
            <button
              @click="toggleFilter()"
              class="bg-blue-500 p-1 rounded text-white mr-2"
            >
              <font-awesome-icon icon="fa-solid fa-filter" class="mr-1" />Шүүх
              утга оруулах
            </button>
            <button
              @click="emptyFilter()"
              class="bg-amber-500 p-1 rounded text-white"
            >
              <font-awesome-icon
                icon="fa-solid fa-eraser"
                class="mr-1"
              />Шүүлтийн утга арилгах
            </button>
          </div>

          <div class="flex gap-3">
            <span class="font-bold">Шүүлтүүрийн утгууд:</span>
            <p v-if="hasSelectedFilters" class="space-x-5">
              <span v-if="selectedFilters.mainCategory"
                >Үндсэн ангилал: {{ selectedFilters.mainCategory }}</span
              >
              <span v-if="selectedFilters.subCategory"
                >Дэд ангилал: {{ selectedFilters.subCategory }}</span
              >
              <span v-if="selectedFilters.approval"
                >Үйл ажиллагаа: {{ selectedFilters.approval }}</span
              >
              <span v-if="selectedFilters.status"
                >Нийтлэгдсэн: {{ selectedFilters.status }}</span
              >
            </p>

            <p v-else>Хоосон байна.</p>
          </div>
        </div>

        <!-- SEARCH -->
        <div class="flex items-center bg-gray-200 w-full rounded text-xs p-2">
          <label for="searchTerm" class="mr-2">Байгууллагын нэр:</label>
          <input
            id="searchTerm"
            name="searchTerm"
            type="text"
            class="rounded p-1 mr-5 w-[20rem] border"
            v-model="searchTerm"
          />

          <button
            @click="fetchDataByCompanyName()"
            class="bg-blue-500 p-1 rounded text-white mr-2"
          >
            <font-awesome-icon
              icon="fa-solid fa-magnifying-glass"
              class="mr-1"
            />Хайх
          </button>
          <button
            @click="resetCompanyName()"
            class="bg-amber-500 p-1 rounded text-white"
          >
            <font-awesome-icon icon="fa-solid fa-eraser" class="mr-1" />Арилгах
          </button>
        </div>

        <div class="flex justify-between items-center text-xs">
          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="dataPerPage"
              id="dataPerPage"
              class="border rounded p-1 text-blue-500"
              v-model="dataPerPage"
            >
              <option value="30">30</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>

          <span>Нийт байгууллагын тоо: {{ totalRequestCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCart
              :totalPages="totalPages"
              :modelValue="currentPage"
              :style="{
                color: 'blue-500',
                height: 7,
                width: 7,
                fontSize: 'xs',
              }"
              @page-changed="onPageChanged"
            />
          </div>
        </div>

        <div v-if="companies.length > 0 && !isLoading">
          <YellowCompanyTable :data="companies" @fetchData="fetchData" />
        </div>

        <div
          v-else-if="companies.length === 0 && !isLoading"
          class="flex justify-center items-center h-[50vh]"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[50vh]">
          <SpinLoading />
        </div>
      </div>
    </div>
  </admin-navbar>

  <ErrorModal v-if="error" :error="error" />
  <YellowCompanyFilter
    v-if="isFilter"
    :filters="selectedFilters"
    @closeModal="toggleFilter"
    @setFilter="setFilter"
  />
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import { onMounted, ref, watch, computed } from "vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import PaginationCart from "@/components/ui/PaginationCart.vue";
import YellowCompanyTable from "@/containers/YellowPage/admin/YellowCompanyTable.vue";
import ErrorModal from "@/components/ui/ErrorModal.vue";
import YellowCompanyFilter from "@/containers/YellowPage/admin/YellowCompanyFilter.vue";

export default {
  name: "AdminYellowCompanyUpdate",
  components: {
    AdminNavbar,
    SpinLoading,
    PaginationCart,
    YellowCompanyTable,
    ErrorModal,
    YellowCompanyFilter,
  },
  setup() {
    const isLoading = ref(false);
    const companies = ref([]);
    const dataPerPage = ref(30);
    const totalRequestCount = ref(null);
    const totalPages = ref(1);
    const currentPage = ref(1);
    const error = ref(null);

    const selectedFilters = ref({
      mainCategory: null,
      subCategory: null,
      approval: null,
      status: null,
    });

    const fetchData = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(`/yellow-page/get/company-list`, {
          params: {
            page: currentPage.value,
            limit: dataPerPage.value,
            ...selectedFilters.value,
          },
        });
        companies.value = res.data.request;
        totalPages.value = res.data.totalPages;
        totalRequestCount.value = res.data.total;
      } catch (err) {
        error.value = "Жагсаалтыг татахад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchData();
    });

    watch([dataPerPage], () => {
      currentPage.value = 1;
      fetchData();
    });

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchData();
    };

    const searchTerm = ref(null);

    const fetchDataByCompanyName = async () => {
      try {
        isLoading.value = true;
        currentPage.value = 1;
        const res = await adminInstance.get(
          `/yellow-page/get/company-list/name?page=${currentPage.value}&limit=${dataPerPage.value}&name=${searchTerm.value}`
        );
        if (res.data && res.status === 200) {
          companies.value = res.data.request;
          totalPages.value = res.data.totalPages;
          totalRequestCount.value = res.data.total;
        }
      } catch (err) {
        error.value = "Байгууллагын нэрээр хайх явцад алдаа гарлаа.";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      } finally {
        isLoading.value = false;
      }
    };

    const resetCompanyName = () => {
      searchTerm.value = null;
      fetchData();
    };

    const isFilter = ref(false);
    const toggleFilter = () => {
      isFilter.value = !isFilter.value;
    };

    const setFilter = async (filters) => {
      selectedFilters.value = filters;
      currentPage.value = 1;
      await fetchData();
    };

    const emptyFilter = () => {
      selectedFilters.value = {
        mainCategory: null,
        subCategory: null,
        approval: null,
        status: null,
      };

      fetchData();
    };

    const hasSelectedFilters = computed(() => {
      return (
        selectedFilters.value.mainCategory !== null ||
        selectedFilters.value.subCategory !== null ||
        selectedFilters.value.approval !== null ||
        selectedFilters.value.status !== null
      );
    });

    return {
      isLoading,
      companies,
      dataPerPage,
      totalRequestCount,
      totalPages,
      currentPage,
      onPageChanged,
      fetchData,
      searchTerm,
      error,
      fetchDataByCompanyName,
      resetCompanyName,
      isFilter,
      toggleFilter,
      selectedFilters,
      setFilter,
      emptyFilter,
      hasSelectedFilters,
    };
  },
};
</script>
