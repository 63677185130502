<template>
  <AdminNavbar>
    <h1 class="font-bold text-lg">Нүүр хуудасны слайд баннер</h1>
    <!-- Add Social Url -->
    <div class="bg-white rounded shadow">
      <div
        class="flex items-center justify-between border-t-4 border-t-blue-500 rounded-t border-b p-3"
      >
        <h6 class="text-base">
          {{
            isMode === "A"
              ? "Жагсаалт"
              : isMode === "B"
              ? "Зураг нэмэх"
              : "Дараалал өөрчлөх"
          }}
        </h6>

        <span class="text-xs space-x-3">
          <button
            @click="toggleMode('A')"
            class="bg-orange-400 p-2 text-white text-xs rounded hover:bg-orange-500"
          >
            <font-awesome-icon icon="fa-solid fa-list" class="mr-1" />Жагсаалт
            харах
          </button>
          <button
            @click="toggleMode('B')"
            class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
          >
            <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />Зураг нэмэх
          </button>
          <button
            @click="toggleMode('C')"
            class="bg-sky-500 p-2 text-white text-xs rounded hover:bg-sky-600"
          >
            <font-awesome-icon icon="fa-solid fa-pen" class="mr-1" />Дараалал
            өөрчлөх
          </button>
        </span>
      </div>

      <!-- Banner list -->
      <div v-if="isMode === 'A'" class="p-3 space-y-5">
        <div v-if="banners.length > 0 && !isLoading" class="p-3">
          <hot-table :settings="hotSettings" :data="banners"></hot-table>
        </div>

        <div
          v-else-if="banners.length === 0 && !isLoading"
          class="flex justify-center items-center h-[70vh]"
        >
          Баннер хоосон байна
        </div>

        <div v-else class="flex justify-center items-center h-[70vh]">
          <SpinLoading :color="'rgb(59 130 246)'" />
        </div>
      </div>

      <!-- Шинээр зураг нэмэх -->
      <div v-else-if="isMode === 'B'" class="p-3 space-y-5">
        <div class="flex">
          <h4 class="w-[15rem] font-bold">
            Баннер зураг<span class="text-red-500">*</span>
          </h4>
          <div class="space-y-3 w-full">
            <div
              class="flex flex-col justify-center items-center"
              :class="mainImage ? 'w-3/4' : 'w-40'"
            >
              <img
                :src="imageSrc"
                alt="image"
                :class="!mainImage ? 'w-14 h-14' : ''"
              />
              <span v-if="!mainImage" class="text-xs text-gray-500"
                >Зураг байхгүй байна</span
              >
            </div>
            <input
              type="file"
              class="w-full border border-gray-300 rounded p-2"
              @change="onImageChange"
              accept="image/jpeg, image/png"
            />
            <span class="text-sky-500 text-xs">
              730X455 (Pixel) хэмжээтэй зураг оруулвал тохиромжтой. Зөвхөн jpg,
              jpeg, png өргөтгөлтэй зураг оруулна уу.
            </span>
          </div>
        </div>

        <button
          @click="insertBanner()"
          class="bg-[#28a745] p-2 text-white mt-5 ml-[12rem] hover:bg-[#208537]"
        >
          Нэмэх
        </button>
      </div>

      <!-- Change sequence -->
      <div v-else class="p-3 space-y-5">
        <table class="w-full text-left">
          <thead class="text-center">
            <tr>
              <th class="p-2 border">Хуучин дараалал</th>
              <th class="p-2 border">Баннер</th>
              <th class="p-2 border">Шинэ дараалал</th>
              <th class="p-2 border">Үйлдэл</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(banner, index) in banners" :key="banner.id">
              <td class="p-2 border text-center">{{ banner.oldSequence }}</td>
              <td class="p-2 border">
                <div class="flex justify-center items-center">
                  <img
                    :src="banner.image"
                    alt="banner"
                    class="h-40 object-fit object-center"
                  />
                </div>
              </td>
              <td class="p-2 border text-center">{{ banner.sequence }}</td>
              <td class="p-2 border">
                <div class="flex items-center justify-center gap-3">
                  <button
                    @click="moveUp(index)"
                    :disabled="index === 0"
                    class="bg-[#28a745] text-white py-1 px-2 rounded disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    <font-awesome-icon icon="fa-solid fa-up-long" />
                  </button>
                  <button
                    @click="moveDown(index)"
                    :disabled="index === banners.length - 1"
                    class="bg-[#28a745] text-white py-1 px-2 rounded disabled:bg-gray-400 disabled:cursor-not-allowed"
                  >
                    <font-awesome-icon icon="fa-solid fa-down-long" />
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <button
          @click="updateBannerSequence"
          class="bg-[#28a745] text-white text-xs p-2 rounded mt-5 hover:bg-[#208537]"
        >
          Дарааллыг хадгалах
        </button>
      </div>
    </div>
  </AdminNavbar>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import adminInstance from "@/lib/adminInstance";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.min.css";
import "handsontable/dist/handsontable.full.css";

registerAllModules();

export default {
  name: "AdminYellowBanner",
  components: {
    AdminNavbar,
    SpinLoading,
    HotTable,
  },
  setup() {
    const isMode = ref("A");
    const toggleMode = (index) => {
      isMode.value = index;
    };

    //Banner List
    const isLoading = ref(false);
    const banners = ref([]);

    const fetchBanners = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get("/yellow-page/get/banner");
        banners.value = res.data.banner.map((banner, index) => ({
          ...banner,
          oldSequence: banner.sequence,
          sequence: index + 1,
        }));
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchBanners();
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const imageRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<div class="relative flex items-center justify-center p-2">
              <img src="${value}" alt="" class="object-fit" />
            </div>`;
    };

    const hotSettings = {
      colHeaders: ["Дараалал", "Зураг", "Үйлдэл"],
      colWidths: [100, 750, 150],
      columns: [
        {
          data: "sequence",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "image",
          readOnly: true,
          renderer: imageRenderer,
        },
        {
          renderer: function (inst, td, row) {
            const banner = banners.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2">
                    <button class="flex justify-center items-center bg-red-500 py-1 px-2 rounded text-white delete-btn">
                        <svg class="fill-current w-3 h-3 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                        <span>Устгах</span>
                    </button>
                </div>`;

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteBanner(banner.id);
              });
            }
          },
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "70vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    // DELETE BANNER
    const deleteBanner = async (id) => {
      if (!window.confirm(`Баннерийг устгах уу?`)) {
        return;
      }

      try {
        isLoading.value = true;
        const res = await adminInstance.delete(
          `/yellow-page/delete/banner?id=${id}`
        );
        if (res.status === 200) {
          isMode.value = "A";
          await fetchBanners();
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    // INSERT BANNER
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        imageSrc.value = defaultImage;
        mainImage.value = null;
      }
    };

    const insertBanner = async () => {
      if (!mainImage.value) {
        return alert("Зураг оруулна уу");
      }

      const formData = new FormData();
      formData.append("image", mainImage.value);

      try {
        const res = await adminInstance.post(
          "/yellow-page/insert/banner",
          formData
        );
        if (res.status === 200) {
          isMode.value = "A";
          await fetchBanners();
        }
      } catch (error) {
        return error;
      }
    };

    watch(isMode, (newMode) => {
      if (newMode !== "B") {
        imageSrc.value = defaultImage;
        mainImage.value = null;
      }
    });

    //Change sequence
    const moveUp = (index) => {
      if (index > 0) {
        const temp = banners.value[index];
        banners.value[index] = banners.value[index - 1];
        banners.value[index - 1] = temp;

        [banners.value[index].sequence, banners.value[index - 1].sequence] = [
          banners.value[index - 1].sequence,
          banners.value[index].sequence,
        ];
      }
    };

    const moveDown = (index) => {
      if (index < banners.value.length - 1) {
        const temp = banners.value[index];
        banners.value[index] = banners.value[index + 1];
        banners.value[index + 1] = temp;

        [banners.value[index].sequence, banners.value[index + 1].sequence] = [
          banners.value[index + 1].sequence,
          banners.value[index].sequence,
        ];
      }
    };

    const updateBannerSequence = async () => {
      try {
        const res = await adminInstance.post(
          "/yellow-page/update/banner-sequence",
          {
            banners: banners.value.map((banner) => ({
              id: banner.id,
              sequence: banner.sequence,
            })),
          }
        );
        if (res.status === 200) {
          window.location.reload();
        } else {
          alert("Дараалал хадгалахад алдаа гарлаа.");
        }
      } catch (err) {
        return err;
      }
    };

    return {
      isLoading,
      banners,
      hotSettings,
      mainImage,
      imageSrc,
      onImageChange,
      isMode,
      toggleMode,
      insertBanner,
      moveUp,
      moveDown,
      updateBannerSequence,
    };
  },
};
</script>
