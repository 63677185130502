<template>
  <div
    class="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-[200] h-full w-full"
  >
    <div
      class="h-[90vh] w-3/4 bg-white rounded p-5 space-y-5 text-sm overflow-y-scroll"
    >
      <div class="flex justify-end">
        <button
          @click="closeModal()"
          class="flex justify-center items-center bg-blue-200 h-8 w-8 rounded-full text-sm text-white hover:bg-blue-300"
        >
          <font-awesome-icon icon="fa-solid fa-x" />
        </button>
      </div>

      <section class="mt-5 space-y-5">
        <div>
          <h5 class="font-bold">Үндсэн ангилал:</h5>
          <div class="flex flex-col gap-2 mt-2 ml-5">
            <label
              v-for="main in mainCategories"
              :key="main.id"
              class="flex items-center gap-2"
            >
              <input
                type="radio"
                name="mainCategory"
                :value="main.id"
                class="hidden peer"
                v-model="selectedFilters.mainCategory"
              />
              <div
                class="w-5 h-5 border-2 border-gray-400 rounded-full flex items-center justify-center peer-checked:border-blue-500 peer-checked:bg-blue-500"
              >
                <div class="w-2.5 h-2.5 bg-white rounded-full"></div>
              </div>
              <span>{{ main.nameMn }}</span>
            </label>
          </div>
        </div>

        <div>
          <h5 class="font-bold">
            Дэд ангилал:
            <span class="italic text-xs text-gray-500 font-light"
              >/Үндсэн ангиллыг сонгосны дараагаар дэд ангиллын сонголтууд гарч
              ирнэ./</span
            >
          </h5>
          <select
            v-model="selectedFilters.subCategory"
            class="w-1/2 border rounded p-2"
          >
            <option disabled value="">Дэд ангилал</option>
            <option v-for="sub in subCategories" :key="sub.id" :value="sub.id">
              {{ sub.name }}
            </option>
          </select>
        </div>

        <div>
          <h5 class="font-bold">Үйл ажиллагаа явуулж буй эсэх:</h5>
          <select
            v-model="selectedFilters.status"
            class="w-1/2 border rounded p-2"
          >
            <option disabled value="">Үйл ажиллагаа</option>
            <option value="true">Тийм</option>
            <option value="false">Үгүй</option>
          </select>
        </div>

        <div>
          <h5 class="font-bold">Нийтлэгдсэн эсэх:</h5>
          <select
            v-model="selectedFilters.approval"
            class="w-1/2 border rounded p-2"
          >
            <option disabled value="">Нийтлэл</option>
            <option value="true">Тийм</option>
            <option value="false">Үгүй</option>
          </select>
        </div>

        <button
          @click="setFilter()"
          class="bg-blue-500 p-1 rounded text-white mr-2"
        >
          <font-awesome-icon icon="fa-solid fa-filter" class="mr-1" />Шүүх
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import { onMounted, ref, watch } from "vue";

export default {
  name: "YellowCompanyFilter",
  emits: ["closeModal", "setFilter"],
  props: {
    filters: Object,
  },
  setup(props, { emit }) {
    const closeModal = () => {
      selectedFilters.value = null;
      emit("closeModal");
    };

    const setFilter = () => {
      emit("setFilter", selectedFilters.value);
      emit("closeModal");
    };

    const selectedFilters = ref(props.filters);

    const mainCategories = ref([
      {
        id: "01",
        nameMn: "Уул уурхайн компани",
        nameEn: "Mining Companies",
      },
      {
        id: "02",
        nameMn: "Ханган нийлүүлэгч",
        nameEn: "Supplier Companies",
      },
      {
        id: "03",
        nameMn: "Үйлчилгээ үзүүлэгч",
        nameEn: "Service Providers",
      },
      {
        id: "04",
        nameMn: "Бусад",
        nameEn: "Others",
      },
    ]);

    const subCategories = ref([]);

    const fetchSubCategories = async () => {
      try {
        const res = await clientYellowInstance.get(
          `/categories-by-main?main=${selectedFilters.value.mainCategory}&language=mn`
        );
        if (res.status === 200) {
          subCategories.value = res.data.subCategories;
        }
      } catch (err) {
        return err;
      }
    };

    watch(
      () => selectedFilters.value.mainCategory,
      () => {
        selectedFilters.value.subCategory = null;
        fetchSubCategories();
      }
    );

    onMounted(() => {
      if (props.filters.mainCategory) {
        fetchSubCategories();
      }
    });

    return {
      closeModal,
      selectedFilters,
      mainCategories,
      subCategories,
      setFilter,
    };
  },
};
</script>
